<template>
  <div>
    <CRow>
      <CCol col="12">
        <CCard>
          <CCardHeader class="d-flex flex-wrap align-items-center">
            <div class="d-flex align-items-center">
              <TMessage content="SKUs in lading bill" bold>
                <template #suffix>
                  <span class="ml-1 text-primary">{{
                    lading_bill_selected.id
                  }}</span>
                  <span class="ml-1">-</span>
                  <span class="ml-1" v-if="lading_bill_selected.customer">{{
                    lading_bill_selected.customer.name ||
                    lading_bill_selected.customer.email
                  }}</span>
                  <span v-else class="ml-1">...</span>
                </template>
              </TMessage>
              <TSpinner :loading="detail_loading" />
            </div>
            <TButtonRefresh
              class="ml-auto"
              @click="fetchLadingBillBoxes"
              :options="{ disabled: detail_loading }"
            />
          </CCardHeader>
          <CCardBody>
            <div class="scroll-x">
              <TMessageNoData
                content="No SKU"
                v-if="!box_lading_bills.length"
              />
              <div class="d-inline-flex">
                <SCardSku
                  v-for="box in box_lading_bills"
                  :key="box.id"
                  :box="box.box"
                  class="my-3 mx-2"
                  :focusQuantity="box.quantity"
                  focusQuantityColor="secondary"
                  focusQuantityDescription="Available quantity"
                  mainQuantityDescription="Total number of boxes"
                  :showWeight="false"
                  :showSfa="false"
                  showOwner
                  style="width: 170px"
                  :ownerId="lading_bill_selected.customer_id"
                  :class="
                    boxListSelected.some((x) => x.id == box.id)
                      ? 'border-success'
                      : 'border-light'
                  "
                >
                  <template #append-content>
                    <div
                      class="d-flex justify-content-center"
                      v-if="box.order_id"
                    >
                      <SMessageShipmentMethod
                        v-if="box.order"
                        :id="box.order.shipment_method_id"
                        iconOnly
                      />
                      <CBadge color="primary" class="h6">
                        {{ box.order_id }}
                      </CBadge>
                    </div>
                  </template>
                  <template #main-quantity>
                    <TMessage
                      v-if="box.box.is_parent_box"
                      content="Combine box"
                    />
                    <TMessageNumber v-else :value="box.quantity_of_owner" />
                  </template>
                  <template #append-actions>
                    <TInputCheckbox
                      :checked="boxListSelected.some((x) => x.id == box.id)"
                      @change="getBoxToList(box)"
                    />
                  </template>
                </SCardSku>
              </div>
            </div>
          </CCardBody>
          <CCardFooter class="d-flex flex-wrap">
            <CLink
              class="ml-2 d-flex text-danger align-items-center"
              @click="boxListSelected = []"
              v-if="boxListSelected.length"
              :disabled="submitLoading"
            >
              <CIcon name="cil-x-circle" class="mr-1" />
              <TMessage content="Deselect all" bold color="text-danger" />
            </CLink>
            <TButton
              class="ml-auto"
              color="success"
              :messageOptions="{ bold: true, noTranslate: true }"
              icon="cil-check"
              :options="{
                disabled: !boxListSelected.length || submitLoading,
              }"
              @click="submit"
            >
              <template #content>
                <TMessage
                  content="Move to a new bill of lading"
                  bold
                  class="ml-2"
                >
                  <template #suffix>
                    <span v-if="boxListSelected.length">
                      {{
                        `(${_.sumBy(boxListSelected, "quantity")} ${$t(
                          "boxes"
                        )})`
                      }}
                    </span>
                  </template>
                </TMessage>
              </template>
            </TButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  data() {
    return {
      boxListSelected: [],
      submitLoading: false,
    };
  },
  watch: {
    "lading_bill_selected.id"() {
      this.boxListSelected = [];
    },
  },
  computed: {
    lading_bill_selected() {
      return this.$store.getters["warehouse.lading_bills.detail"];
    },
    detail_loading() {
      return this.$store.getters["warehouse.box_lading_bills.loading"];
    },
    box_lading_bills() {
      return this.$store.getters["warehouse.box_lading_bills.list"] || [];
    },
  },
  methods: {
    fetchLadingBillBoxes() {
      this.$store.dispatch("warehouse.box_lading_bills.apply-query", {
        "filter[lading_bill_id]": this.lading_bill_selected.id,
        include: "box.children.owners,box.tags,box.children.owners.order",
      });
    },
    getBoxToList(box) {
      const existIndex = this.boxListSelected.findIndex((x) => x.id == box.id);
      if (existIndex == -1)
        this.boxListSelected.push({
          id: box.id,
          box_id: box.box_id,
          order_id: box.order_id,
          quantity: Math.abs(box?.quantity) || 1,
        });
      else this.boxListSelected.splice(existIndex, 1);
    },
    submit() {
      this.submitLoading = true;
      let data = {
        lading_bill_id: this.lading_bill_selected.id,
      };
      let listConvert = [];
      this.boxListSelected.forEach((box) => {
        const indexE = listConvert.findIndex((x) => x.box_id == box.box_id);
        if (indexE >= 0)
          listConvert[indexE]?.list.push({
            order_id: box?.order_id,
            quantity: box?.quantity,
          });
        else {
          const item = this.lodash.pickBy({
            box_id: box.box_id,
            list: [{ order_id: box.order_id, quantity: box.quantity }],
          });
          listConvert = [...listConvert, item];
        }
      });
      listConvert.forEach((box, index) => {
        const boxC = { box_id: box.box_id };
        const boxCArray = this.lodash.mapKeys(boxC, (value, key) => {
          return `boxes[${index}][${key}]`;
        });
        box.list.forEach((item, itemIndex) => {
          const itemCArray = this.lodash.mapKeys(item, (value, key) => {
            return `boxes[${index}][orders][${itemIndex}][${key}]`;
          });
          data = { ...data, ...itemCArray };
        });
        data = { ...data, ...boxCArray };
      });
      this.$tomoni.warehouse.decoup_lading_bill
        .create(this.lodash.pickBy(data))
        .then(({ data }) => {
          this.$store.dispatch("warehouse.lading_bills.fetch");
          this.$store.commit("toasts.push", {
            message: "Updated",
            type: "success",
          });
          this.$router.push(
            this.lodash.getReferenceLink("lading_bill", data.id)
          );
        })
        .catch((error) => {
          this.$store.dispatch("errors.push-http-error", error);
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
  },
};
</script>

<template>
  <div class="d-flex w-100">
    <SSelectCustomer
      :value.sync="search"
      @change="triggerSearch($event.id)"
      noCustomLabel
      prependAll
      noFetchStore
      class="w-100 mr-1"
      :prepend="{ id: '', name: $t('All customer') }"
    />
    <SButtonFilter
      :active="!lodash.isEmpty(query)"
      :badge="lodash.size(query)"
      class="ml-auto my-auto"
      @click="show = true"
    />
    <TModal title="Filter" :show="show" @update:show="show = $event">
      <template #actions>
        <SButtonClearFilter
          @click="clear"
          :disabled="loading || lodash.isEmpty(allQuery)"
        />
        <SButtonSearchFilter :disabled="loading" @click="onFilter" />
      </template>

      <CRow>
        <CCol col="12" lg="6" md="6" class="mb-3">
          <TInputText
            :value.sync="filter['id']"
            label="Bill of lading code"
            placeholder="Bill of lading code"
            @update:value="filter.id = $event"
            @keydown.enter.native="onFilter"
          />
        </CCol>
        <CCol col="12" lg="6" md="6" class="mb-3">
          <SSelectInvoice
            :value.sync="filter['container_id']"
            label="Invoice"
            searchKey="name"
            prependAll
          />
        </CCol>
        <CCol col="12" lg="12" md="12" class="mb-3">
          <TInputCheckbox
            class="ml-auto"
            label="The lading bill available for container"
            :checked="available_for_container"
            @change="setAvailableForContFilter"
          />
        </CCol>
      </CRow>
    </TModal>
  </div>
</template>

<script>
import filter from "../mixins/filter";
export default {
  mixins: [filter],
  data() {
    return {
      blockParams: ["tab", "lading_bill_id", "action"],
      searchFilter: "customer_id",
      store: "warehouse.lading_bills",
      available_for_container: false,
    };
  },
  watch: {
    "filter.available_for_container": {
      immediate: true,
      handler(val) {
        if (val) this.available_for_container = true;
      },
    },
  },
  methods: {
    setAvailableForContFilter(value) {
      this.available_for_container = value;
      if (value) this.filter.available_for_container = 1;
      else this.filter.available_for_container = 0;
    },
    clear() {
      this.clearFilter();
      this.available_for_container = false;
    },
  },
};
</script>

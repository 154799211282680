<template>
  <CRow class="mb-5">
    <CCol lg="3">
      <TAside>
        <CListGroup>
          <CListGroupItem :to="`?action=new_lading_bill`" color="success">
            <TMessage content="New lading bill" bold alignment="center">
              <template #prefix>
                <CIcon name="cil-plus" class="mr-2" />
              </template>
            </TMessage>
          </CListGroupItem>
          <CListGroupItem>
            <FilterForLadingBill :queries.sync="query" />
          </CListGroupItem>
          <CListGroupItem
            v-for="(lading_bill, index) in lading_bills"
            :key="`${index}${lading_bill.id}`"
            :color="
              lading_bill_id_selected == lading_bill.id
                ? 'primary'
                : 'secondary'
            "
            :to="
              _.getAppendRouteQuery($route, {
                lading_bill_id: lading_bill.id,
              })
            "
          >
            <SCardLadingBill :lading_bill="lading_bill" tiny />
          </CListGroupItem>
          <CListGroupItem v-if="!lading_bills.length">
            <TMessageNotFound />
          </CListGroupItem>
          <CListGroupItem v-if="list_fetching">
            <TSpinner :loading="list_fetching" />
          </CListGroupItem>
          <SButtonLoadmore store="warehouse.lading_bills" />
        </CListGroup>
      </TAside>
    </CCol>
    <CCol lg="9">
      <TTabs
        v-show="lading_bill_id_selected"
        :tabs="tabs"
        :activeTab="$route.query.tab"
        @change-tab="changeTab"
      >
        <template #list>
          <SCardSkuLadingBill
            :lading_bill_id="lading_bill_id_selected"
            showSuggest
          />
        </template>
        <template #detail>
          <SCardLadingBillDetail removable :ladingBill="lading_bill_selected" />
        </template>
        <template #compensation>
          <SCardCompensationLadingBill
            :compensations="lading_bill_selected.compensations"
          />
        </template>
        <template #change-ldb>
          <TransferBoxLadingBill />
        </template>
      </TTabs>
      <CCard v-show="!lading_bill_id_selected">
        <CCardHeader class="d-flex flex-wrap align-items-center">
          <TMessage content="Create new lading bill" bold />
        </CCardHeader>
        <CCardBody>
          <SSelectCustomer
            label="Select customer"
            :value.sync="customer_id_selected"
            class="w-100"
            noFetchStore
          />
          <SSelectShipmentMethodBox
            @update:value="shipment_method_id = $event"
            class="mt-3"
            :value.sync="shipment_method_id"
            :key="shipmentMKey"
          />
        </CCardBody>
        <CCardFooter>
          <div class="float-right">
            <TButton
              content="Create lading bill"
              icon="cilPlus"
              @click="createLadingBill"
              :disabled="lading_bills_creating"
            />
          </div>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import filterable from "../../mixins/filterable";
import FilterForLadingBill from "../../components/FilterForLadingBill.vue";
import TransferBoxLadingBill from "../../components/TransferBoxLadingBill.vue";
export default {
  mixins: [filterable],
  components: {
    FilterForLadingBill,
    TransferBoxLadingBill,
  },
  data() {
    return {
      tabs: [
        {
          key: "list",
          name: "SKU list",
          icon: "cisBox",
        },
        {
          key: "detail",
          name: "Lading bill detail",
          icon: "cisLibraryBooks",
        },
        {
          key: "compensation",
          name: "Compensation",
          icon: "cisDocument",
        },
        {
          key: "change-ldb",
          name: "Transfer the bill of lading",
          icon: "cil-list-high-priority",
        },
      ],
      customer_id_selected: null,
      shipment_method_id: null,
      shipmentMKey: "",
    };
  },
  mounted() {
    if (this.lodash.isEmpty(this.query)) {
      this.$store.dispatch("warehouse.lading_bills.fetch.if-first-time");
    } else {
      this.$store.dispatch("warehouse.lading_bills.apply-query", this.query);
    }
    this.$store.dispatch("warehouse.freight_charge_types.fetch.if-first-time");
  },
  computed: {
    lading_bills() {
      return this.$store.getters["warehouse.lading_bills.list"];
    },
    lading_bill_id_selected() {
      return this.$route.query.lading_bill_id;
    },
    lading_bill_selected() {
      return this.$store.getters["warehouse.lading_bills.detail"];
    },
    list_fetching() {
      return this.$store.getters["warehouse.lading_bills.fetching"];
    },
    lading_bills_creating() {
      return this.$store.getters["warehouse.lading_bills.creating"];
    },
  },
  methods: {
    changeTab(tab) {
      let query = this.lodash.omit({ ...this.$route.query, tab: tab }, [
        "action",
      ]);
      this.$router.push({ query: query });
    },
    createLadingBill() {
      this.$store
        .dispatch("warehouse.lading_bills.create", {
          customer_id: this.customer_id_selected,
          shipment_method_id: this.shipment_method_id,
        })
        .then(({ id }) => {
          this.$router.push({ query: { lading_bill_id: id } });
          this.shipment_method_id = "";
          this.shipmentMKey = this.lodash.resetKey("shipmentMKey");
          this.customer_id_selected = null;
        });
    },
  },
};
</script>
